import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { DeveloperApiService } from '@shared/services';
import { RecommendationValuePipe, ToTitlePipe, ToSelectOptionsPipe, FilterByUsageCountPipe } from '@shared/pipes';
import { environment } from '@environments/environment';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { MsalBroadcastService, MsalRedirectComponent } from '@azure/msal-angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { TabContainerComponent } from '@tab-container/tab-container.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  ButtonV2Module,
  EChartModule,
  HeaderModule,
  UserState,
  ActionRequiredState,
  AppEnvironmentState,
  TableV2Module,
  SearchBarV2Module,
  CheckboxV2Module,
  AutocompleteSelectV2Module,
  IconModule,
  ComplexDialogV2Module,
  SelectV2Module,
  MultiSelectV2Module,
  TextfieldV2Module,
  InputV2Module,
  TabMenuV2Module,
  ToggleV2Module,
  SidebarV2Module,
  PermissionsState,
  GeaTranslateHttpLoader,
} from '@gea/digital-ui-lib';

import { CustomerOverviewComponent } from './customer-overview';
import {
  BarChartComponent,
  EmptyDataPlaceholderComponent,
  HintComponent,
  InfoBoxComponent,
  HeadingComponent,
  UpdateTimeComponent,
  ImagesPreviewComponent,
  SpinnerComponent,
  CloseButtonComponent,
  ConnectivityStatusComponent,
} from '@shared/components';
import {
  DataChartComponent,
  DateRangeComponent,
  ProblemSolveComponent,
  ReadonlyActionsComponent,
  RecommendationsListComponent,
  RelatedOperatingDataComponent,
  RelatedOperatingDataTogglesComponent,
  ProblemSolveDialogComponent,
  TroubleshootingComponent,
  TroubleshootingFiltersComponent,
} from '@tab-container/troubleshooting';
import {
  FaultAnalysisComponent,
  FaultFiltersComponent,
  FaultHistoryComponent,
  OmniLinkComponent,
  FaultAnalysisSectionComponent,
  LastInstanceRendererComponent,
} from '@tab-container/fault-analysis';
import {
  FaultRecommendationsState,
  FaultsState,
  MachinesState,
  MembershipsState,
  OrganizationsState,
  RecommendationsState,
  RelatedOperatingDataState,
  SystemHealthState,
} from '@shared/state';
import {
  HealthCheckComponent,
  HealthCheckDialogComponent,
  HealthHeaderComponent,
  HealthFiltersComponent,
  HealthMachineComponent,
  SystemHealthComponent,
  MachineStatusComponent,
} from './tab-container/system-health';
import {
  DateRendererComponent,
  DeleteRecommendationDialogComponent,
  FaultRecommendationsComponent,
  FaultRecommendationsFiltersComponent,
  RecommendationDialogComponent,
  RecommendationOrderListComponent,
  ResolvedFaultStatusRendererComponent,
  SolvedFaultViewDetailRendererComponent,
  SolvedFaultsComponent,
  SolvedFaultsDetailDialogComponent,
  SolvedFaultImagesRendererComponent,
} from '@tab-container/fault-recommendations';
import { MatButtonModule } from '@angular/material/button';
import { MachineOverviewComponent } from '@tab-container/machine-overview';
import { TooltipModule } from 'primeng/tooltip';
import { OrderListModule } from 'primeng/orderlist';
import {
  EditableActionsComponent,
  RecommendationsComponent,
  RecommendationImagesDialogComponent,
  RecommendationItemComponent,
} from '@troubleshooting/recommendations';
import { ContactDialogComponent } from '@troubleshooting/problem-solve/contact-dialog';

const translationFactory = (http: HttpClient) => {
  return new GeaTranslateHttpLoader(http, environment.translationBlob, [], ['./assets/i18n/']);
};

@NgModule({
  declarations: [
    AppComponent,
    CustomerOverviewComponent,
    FaultAnalysisComponent,
    FaultFiltersComponent,
    FaultHistoryComponent,
    InfoBoxComponent,
    TabContainerComponent,
    TroubleshootingComponent,
    TroubleshootingFiltersComponent,
    BarChartComponent,
    RecommendationsListComponent,
    HintComponent,
    OmniLinkComponent,
    ProblemSolveComponent,
    RecommendationsComponent,
    ProblemSolveDialogComponent,
    RelatedOperatingDataComponent,
    DataChartComponent,
    RelatedOperatingDataTogglesComponent,
    DateRangeComponent,
    SystemHealthComponent,
    HealthCheckDialogComponent,
    HealthCheckComponent,
    HealthFiltersComponent,
    HealthMachineComponent,
    EmptyDataPlaceholderComponent,
    FaultRecommendationsComponent,
    RecommendationDialogComponent,
    DeleteRecommendationDialogComponent,
    MachineOverviewComponent,
    RecommendationOrderListComponent,
    HeadingComponent,
    FaultRecommendationsFiltersComponent,
    ContactDialogComponent,
    FaultAnalysisSectionComponent,
    RecommendationImagesDialogComponent,
    ReadonlyActionsComponent,
    ToTitlePipe,
    RecommendationValuePipe,
    UpdateTimeComponent,
    ImagesPreviewComponent,
    ToSelectOptionsPipe,
    FilterByUsageCountPipe,
    HealthHeaderComponent,
    ConnectivityStatusComponent,
    LastInstanceRendererComponent,
    SpinnerComponent,
    SolvedFaultsComponent,
    DateRendererComponent,
    SolvedFaultViewDetailRendererComponent,
    SolvedFaultsDetailDialogComponent,
    SolvedFaultImagesRendererComponent,
    ResolvedFaultStatusRendererComponent,
    CloseButtonComponent,
    RecommendationItemComponent,
    EditableActionsComponent,
    MachineStatusComponent,
  ],
  imports: [
    NgxsModule.forRoot(
      [
        OrganizationsState,
        MachinesState,
        FaultsState,
        FaultRecommendationsState,
        RecommendationsState,
        RelatedOperatingDataState,
        SystemHealthState,
        UserState,
        ActionRequiredState,
        AppEnvironmentState,
        MembershipsState,
        PermissionsState,
      ],
      {
        developmentMode: !environment.production,
        compatibility: {
          strictContentSecurityPolicy: true,
        },
      }
    ),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    AccordionModule,
    HeaderModule.forRoot({
      redirectUri: environment.oAuth.redirectUri,
      postLogoutRedirectUri: environment.oAuth.postLogoutRedirectUri,
      clientId: environment.oAuth.clientId,
      authority: environment.oAuth.authority,
      authorityDomain: environment.oAuth.authorityDomain,
      portalURL: environment.portal?.baseURL || '',
      urlsToIntercept: [],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    ButtonV2Module,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatChipsModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      extend: true,
      defaultLanguage: 'de-DE',
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: translationFactory,
      },
    }),

    MatProgressSpinnerModule,
    EChartModule,
    MatButtonModule,
    TableV2Module,
    ButtonV2Module,
    SearchBarV2Module,
    IconModule,
    TooltipModule,
    ComplexDialogV2Module,
    SelectV2Module,
    MultiSelectV2Module,
    OrderListModule,
    CheckboxV2Module,
    AutocompleteSelectV2Module,
    TextfieldV2Module,
    TabMenuV2Module,
    ToggleV2Module,
    InputV2Module,
    SidebarV2Module,
  ],
  providers: [
    HttpClientModule,
    TranslatePipe,
    MsalBroadcastService,
    {
      provide: 'storageURL',
      useValue: 'https://strgaccappassetsdev.blob.core.windows.net/strg-container-i18n-dev/',
    },
    {
      provide: 'hrtBaseUrl',
      useValue: environment.hrt.baseURL || environment.baseURL,
    },
    {
      provide: 'isAdminInLocal',
      useFactory: () => environment.name === 'local' && (environment.isAdminInLocal || false),
    },
    {
      provide: 'hrtFaultDatabaseBaseUrl',
      useValue: environment.hrtFaultdatabase?.baseUrl || '',
    },
    {
      provide: 'hrtFaultDatabaseSubscriptionKey',
      useValue: environment.hrtFaultdatabase?.subscriptionKey || '',
    },
    {
      provide: 'baseUrl',
      useValue: environment.baseURL,
    },
    {
      provide: 'hrtSubscriptionKey',
      useValue: environment.hrt.subscriptionKey,
    },
    {
      provide: 'subscriptionKey',
      useValue: environment.subscriptionKey,
    },
    {
      provide: 'iconPath',
      useValue: 'https://strgaccappassetsdev.blob.core.windows.net/strg-container-icons-dev/',
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(
    readonly devApi: DeveloperApiService,
    readonly translate: TranslateService
  ) {}
}
